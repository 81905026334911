// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAsq6Qipit2tDhcC4QiCP3TQaJ_ZxkgFyQ",
  authDomain: "aashray2k24-76af4.firebaseapp.com",
  projectId: "aashray2k24-76af4",
  storageBucket: "aashray2k24-76af4.appspot.com",
  messagingSenderId: "987180241196",
  appId: "1:987180241196:web:47c8ff20f4237c0465dc89"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);