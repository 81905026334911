import styled from "styled-components";
import ImgBg from '../../images/landingpage.jpg';

export const HeroContainer = styled.div`
    background: linear-gradient(to right, rgba(0,0,0,0.7), rgba(0,0,0,0.1)), url(${ImgBg});
    height: 100vh;
    background-position: center;
    background-size: cover;
    overflow-x: hidden;
    margin-top: 0;

    @media screen and (max-width: 800px){
        background-position: 80%;

    }
`;

export const HeroContent = styled.div`
    height: calc(100vh - 80px);
    max-height: 100%;
    width: 100vw;
    padding: 0rem calc((100vw - 1300px) /2) 
`;

export const HeroItems = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    max-height: 100%;
    padding: 0 2rem;
    width: 650px;
    color: #fff;
    text-transform: uppercase;
    line-height: 1;
    font-weight: bold;

    @media screen and (max-width: 650px) {
        width: 100%;
    }
`;

export const HeroH1 = styled.h1`
    
    font-size: clamp(1.5rem, 4.1vw, 4rem);
    font-family: 'Lobster', cursive;
    left:10%;
   
    
`;



export const HeroBtn = styled.button`
    font-size: 1.4rem;
    padding: 1rem 3rem;
    border-radius: 0.5rem;
    border: none;
    background: #e31837;
    color: #fff;
    transition: 0.2s ease-out;

    &:hover {
        background: #ffc500;
        transition: 0.2s ease-out;
        cursor: pointer;
        color: #000;
    }
`;